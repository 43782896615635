<i18n>
	{
		"en": {
			"loginTxt": "Grievance Form",
			"dontHave": "Don't have account ?",
			"register": "Register",
			"checkNimbuzzApp": "Please check your Nimbuzz app for verification code.",
			"forgotPass": "Forgot Password?",
			"confirm": "Confirm",
			"enterNumber": "Enter mobile number",
			"customerName": "Enter customer name",
			"customerEmail": "Enter customer email",
			"description": "Please provide description of your problem",
			"uploadFile": "Upload related document",
			"enterPass": "Enter password",
			"forgotPass": "Forgot Password?",
			"login": "Submit",
			"verifyActivationCode": "Please verify your activation code"
		},
		"np": {
			"loginTxt": "गुनासो फारम",
			"dontHave": "खाता छैन?",
			"register": "दर्ता गर्नुहोस्",
			"checkNimbuzzApp": "प्रमाणीकरण कोडको लागि कृपया आफ्नो Nimbuzz एप जाँच गर्नुहोस्।",
			"forgotPass": "पासवर्ड बिर्सनुभयो?",
			"confirm": "पुष्टि गर्नुहोस्",
			"enterNumber": "मोबाइल नम्बर राख्नुहोस्",
			"customerName": "ग्राहकको नाम राख्नुहोस्",
			"customerEmail": "ग्राहकको इमेल राख्नुहोस्",
			"description": "कृपया आफ्नो समस्याको विवरण दिनुहोस्",
			"uploadFile": "सम्बन्धित कागजात अपलोड गर्नुहोस्",
			"enterPass": "पासवर्ड राख्नुहोस्",
			"forgotPass": "पासवर्ड बिर्सनुभयो?",
			"login": "पेस गर्नुहोस्",
			"verifyActivationCode": "कृपया आफ्नो सक्रियता कोड प्रमाणित गर्नुहोस्"
			}
	}
</i18n>
<template lang="pug">
	.hero-banner
		.hero-banner-content
			.container
				.login-modal.login-box
					.modal-header
						h6.font-weight-bold {{ $t("loginTxt") }}
					.modal-body
						.show-message(v-if='message') {{message}}
						.row
							.col-sm-12.col-lg-7
								form(@submit.prevent='authenticate')
								
									.form-group.input-group
										input.form-control( type="text"
											v-model="customerName"
											name="customer_name"
											autocomplete="off"
											:placeholder="$t('customerName')"
											required
											autofocus)
									.form-group.input-group
										input.form-control( type="email"
											v-model="customerEmail"
											:placeholder="$t('customerEmail')"
											required
											autofocus)
									.form-group.input-group
										.input-group-prepend
											.input-group-text +977
										input.form-control( type="tel"
											pattern="[0-9]+"
											v-model="phoneNumber"
											maxlength="10"
											:placeholder="$t('enterNumber')"
											required
											autofocus)
									.form-group.input-group
										b-form-select( 
											size="lg"
											v-model="natureOfGrievance"
											maxlength="10"
											:placeholder="$t('enterNumber')"
											:options="grievanceOptions"
											required
											autofocus)
									.form-group.input-group
										b-form-file( type="file"
												pattern="[0-9]+"
												maxlength="10"
												v-model="file"
												:placeholder="$t('uploadFile')"
												autofocus)
									.form-group.input-group
										b-form-textarea( type="file"
												:placeholder="$t('description')"
												required
												autofocus)
									div(class="g-recaptcha" data-sitekey="6LdExpwpAAAAAK-E48kpfnfGjAXYMuvW4znZBkAK")
									.text-center.mt-4
										button.btn.btn-danger.btn-block(:disabled='loading || complaintSubmitted' type="submit") 
											b-spinner.text-center(small v-if='loading')
											.text-center(v-else) {{ $t("login") }}


		.hero-silhouette
		
</template>

<script>
import {
	getSecret,
	makePasswordHash,
	REMOTE_SERVER_1,
	prepareHeaders,
} from "@/helpers/general";
import uuid from "uuid";
import axios from "@/axios";
export default {
	name: "login",
	data() {
		return {
			user: {
				userid: "",
				password: "",
				organizationid: "kuraakani",
				secret: "",
				UUID: "",
			},
			webAuthenticationCode: "",
			normalPassword: "",
			phoneNumber: "",
			error: null,
			loading: false,
			message: "",
			isWebAuthenticationRequired: false,
			customerName: "",
			file: null,
			customerEmail: "",
			complaintSubmitted: false,
			grievanceType: null,
			natureOfGrievance: null,
			grievanceOptions: [{value: null, text: "Select nature of grievance"}, "Transaction Dispute", "Fund Transfer Related","Bank Transfer Related", "Load Wallet Related", "Service Related", "Others"]
		};
	},
	async mounted() {
		
	},
	methods: {
		authenticate() {
			if (!window.grecaptcha.getResponse()) {
				this.$bvToast.toast("Please compelete captcha before proceeding", {
					title: "Captcha Error",
					variant: "danger"
				})
				return
			}
			
			this.loading = true;
			
			let data = new FormData()
			if (this.file) {
				data.append("file", this.file)
			}
			data.append("customerName", this.customerName)
			data.append("customerEmail", this.customerEmail)
			data.append("phoneNumber", this.phoneNumber)
			data.append("description", this.description)
			data.append("natureOfGrievance", this.natureOfGrievance)
			data.append("cpt", window.grecaptcha.getResponse())
			axios
				.post("/api/v4/grievance-submit", data)
				.then((r) => {
					console.log(r.data)
					this.loading = false
					this.message = r.data.message
					
					this.$bvToast.toast(r.data.message, {
					title: "Success",
					variant: "success"
				})
					this.complaintSubmitted = true
				})
				.catch((_) => { });
		},
		validateWebAuthenticationCode() {
			this.loading = true;
			localStorage.removeItem("user");
			let UUID = localStorage.getItem("_UUID") || uuid();
			localStorage.setItem("_UUID", UUID);
			this.user = {
				...this.user,
				secret: getSecret(),
				userid: "+977" + this.phoneNumber,
				password: makePasswordHash(this.normalPassword),
				UUID: UUID,
				webAuthenticationCode: this.webAuthenticationCode,
			};
			axios
				.post(REMOTE_SERVER_1 + "user/signin/validate", this.user)
				.then((r) => {
					if (r.data.data) {
						sessionStorage.setItem("_token", r.data.data.newToken);
						prepareHeaders(r.data.data.newToken, axios);
						this.$store.commit("SET_USER", r.data.data.user);
						localStorage.setItem(
							"user",
							JSON.stringify({
								name: r.data.data.user.name,
								imageURL: r.data.data.user.avatar.picture.nameOnServer,
								uid: r.data.data.user._id,
							})
						);
						const intent = sessionStorage.getItem("intent");
						if (intent) {
							this.$router.push(`vendor/payment?intent=${intent}`);
							sessionStorage.removeItem("intent");
							return;
						}
						location.reload();
					} else {
						this.message = "Unable to log you in.";
						this.loading = false;
					}
				})
				.catch((_) => { });
		},
	},
};
</script>

<style scoped>
.error {
	text-align: center;
	color: red;
}
</style>
